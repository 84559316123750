import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../../components/Layout";
import Content, { HTMLContent } from "../../components/Content";

// share
import SharePartsOfTroublePagePreview from "./../share-parts-of-trouble-page";
import SharePlacesPageTemplate from "./../share-places-page";
import SharePickupNewsPageTemplate from "./../share-pickup-news-page";
import ShareHistoryPageTemplate from "./../share-history-page";
import ShareInfomationPageTemplate from "./../share-infomation-page";
import { DullnessRankingPageTemplate } from "../dullness/dullness-ranking-page";

// data
import internalMedicineData from "./../../data/machines/internal_medicine.json";

// services
import isBlank from "../../services/checkBlank";

export const InternalMedicineyPageTemplate = ({
  title,
  content,
  contentComponent,
}) => {
  return (
    <>
      <div className="c-bread">
        <div className="c-bread__inner">
          <div className="c-bread__item">
            <Link className="c-bread__link" to={"/"}>
              <div className="c-bread__text">トップ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <div className="c-bread__text">
              {internalMedicineData[0] && internalMedicineData[0].title.length
                ? internalMedicineData[0].title
                : null}
            </div>
          </div>
        </div>
      </div>

      <section className="l-section-lv2 l-main-media-hero">
        <div className="l-main-media-hero__inner">
          <div className="c-simple-title -small">
            <h2 className="c-simple-title__text">
              {internalMedicineData[0] && internalMedicineData[0].title.length
                ? internalMedicineData[0].title
                : null}
            </h2>
          </div>

          {internalMedicineData[0] &&
          internalMedicineData[0].description.length ? (
            <div className="c-text u-pc-mt20 u-sp-mt10 u-pc-mb40 u-sp-mb20">
              {internalMedicineData[0].description}
            </div>
          ) : null}

          {internalMedicineData[0] &&
          internalMedicineData[0].main_medicine_of_stain.length
            ? internalMedicineData[0].main_medicine_of_stain.map((item) => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <div className="c-tile3">
                        <div className="c-tile3__inner">
                          <div className="c-tile3__body">
                            <div className="c-tile3__body__row">
                              {item.lables.map((item) => {
                                return (
                                  <div className="c-tile3__body__item">
                                    <p className="c-tile3__body__text">
                                      {item.text}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>

                      {!isBlank(item.descriptions) &&
                        item.descriptions.map(
                          (description: {
                            title: string;
                            items: { head: string; body: string }[];
                            page_link: string;
                          }) => {
                            return (
                              <section className="c-doc-title__section">
                                <h4 className="c-doc-title">
                                  {description.title}
                                </h4>

                                {!isBlank(description.items) && (
                                  <div className="c-doc-table">
                                    <div className="c-doc-table__inner">
                                      {description.items.map((item: any) => {
                                        return (
                                          <div className="c-doc-table__cell">
                                            <p className="c-doc-table__cell__title">
                                              {item.head}

                                              <span className="c-doc-table__cell__adjust-text">
                                                :
                                              </span>
                                            </p>
                                            <p className="c-doc-table__cell__text">
                                              {item.body}
                                            </p>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                                {!isBlank(description.page_link) && (
                                  <p className="c-doc-link-text">
                                    <a
                                      className="c-doc-link-text__link"
                                      href={description.page_link}
                                      target="_blank"
                                    >
                                      詳細はこちら
                                    </a>
                                  </p>
                                )}
                              </section>
                            );
                          }
                        )}
                    </section>
                  </>
                );
              })
            : null}

          {internalMedicineData[0] &&
          internalMedicineData[0].main_medicine_of_chloasma.length
            ? internalMedicineData[0].main_medicine_of_chloasma.map((item) => {
                return (
                  <>
                    <section className="u-pc-mt100 u-sp-mt60">
                      <div className="c-border-simple-title">
                        <h3 className="c-border-simple-title__text">
                          {item.title}
                        </h3>
                      </div>

                      <div className="c-tile3">
                        <div className="c-tile3__inner">
                          <div className="c-tile3__body">
                            <div className="c-tile3__body__row">
                              {item.lables.map((item) => {
                                return (
                                  <div className="c-tile3__body__item">
                                    <p className="c-tile3__body__text">
                                      {item.text}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                );
              })
            : null}

          {internalMedicineData[0] &&
          internalMedicineData[0].main_medicine_of_tattoo_removal.length
            ? internalMedicineData[0].main_medicine_of_tattoo_removal.map(
                (item) => {
                  return (
                    <>
                      <section className="u-pc-mt100 u-sp-mt60">
                        <div className="c-border-simple-title">
                          <h3 className="c-border-simple-title__text">
                            {item.title}
                          </h3>
                        </div>

                        <div className="c-tile3">
                          <div className="c-tile3__inner">
                            <div className="c-tile3__body">
                              <div className="c-tile3__body__row">
                                {item.lables.map((item) => {
                                  return (
                                    <div className="c-tile3__body__item">
                                      <p className="c-tile3__body__text">
                                        {item.text}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </>
                  );
                }
              )
            : null}

          {typeof window !== "undefined" &&
            window.location.hash === "#prev-dullness-screen" && (
              <>
                <div className="c-simple-title -small u-pc-mt100 u-sp-mt50">
                  <h2 className="c-simple-title__text">
                    肝斑、くすみ施術ランキング
                  </h2>
                </div>

                <DullnessRankingPageTemplate />
              </>
            )}
        </div>
      </section>
    </>
  );
};

InternalMedicineyPageTemplate.propTypes = {
  title: PropTypes.string,
};

const InternalMedicineyPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <InternalMedicineyPageTemplate title={post.frontmatter.title} />
      <SharePartsOfTroublePagePreview />
      <SharePlacesPageTemplate />
      <SharePickupNewsPageTemplate />
      <ShareHistoryPageTemplate />
      <ShareInfomationPageTemplate />
    </Layout>
  );
};

InternalMedicineyPage.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default InternalMedicineyPage;

export const internalMedicinePageQuery = graphql`
  query InternalMedicineyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
